import React, { forwardRef } from 'react'
import cx from 'classnames'
import { Link as RouterLink } from 'router'
import { Message } from 'intl'


export type HrefProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string
  href?: string
  to?: string
  toTab?: string
  mailto?: string
  message?: Intl.Message | string
  block?: boolean
  inlineBlock?: boolean
  disabled?: boolean
  'data-testid'?: string
}

const Href = forwardRef<HTMLAnchorElement, HrefProps>((props, ref) => {
  const { children, className, href, to, toTab, mailto, message, block, inlineBlock, disabled, ...rest } = props

  const linkClassName = cx(className, {
    'block': block,
    'inline-block': inlineBlock,
    'pointer-events-none': disabled,
  })

  const content = message ? (
    <Message value={message} />
  ) : children

  if (href) {
    return (
      <a
        className={linkClassName}
        href={href}
        rel="noopener noreferrer"
        ref={ref}
        {...rest}
      >
        {content}
      </a>
    )
  }

  if (to) {
    return (
      <RouterLink
        className={linkClassName}
        to={to}
        ref={ref}
        {...rest}
      >
        {content}
      </RouterLink>
    )
  }

  if (toTab) {
    return (
      <a
        className={linkClassName}
        href={toTab}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        {...rest}
      >
        {content}
      </a>
    )
  }

  if (mailto) {
    return (
      <a
        className={linkClassName}
        href={`mailto:${mailto}`}
        ref={ref}
        {...rest}
      >
        {content || mailto}
      </a>
    )
  }

  return (
    <a
      className={linkClassName}
      ref={ref}
      {...rest}
    >
      {content}
    </a>
  )
})

Href.displayName = 'Href'


export default Href
