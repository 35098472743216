import React from 'react'
import cx from 'classnames'

import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import type { ButtonBaseProps } from 'components/inputs'

import s from './ActionListItem.module.css'


export const styles = [ 'gold-50', 'black' ] as const

type ActionListItemStyle = typeof styles[number]

export type ActionListItemProps = ButtonBaseProps & {
  title: Intl.Message | string
  label?: Intl.Message | string
  style?: ActionListItemStyle
  withBorder?: boolean
  withBorderBottom?: boolean
  withArrow?: boolean
}

const ActionListItem: React.FunctionComponent<ActionListItemProps> = (props) => {
  const {
    children, className, title, label, loading, disabled, style = 'gold-50',
    withBorder, withBorderBottom, withArrow = true,
    ...rest
  } = props

  const buttonClassName = cx(className, s.button, 'block w-full cursor-pointer py-20', s[`style-${style}`], {
    [s.disabled]: disabled,
    [s.withBorder]: withBorder,
    [s.withBorderBottom]: withBorderBottom,
  })

  const isLabelVisible = Boolean(label)

  const buttonBaseProps = {
    className: buttonClassName,
    loading,
    disabled,
    ...rest,
  }

  const textContent = (
    <Text className="whitespace-nowrap text-left" message={title} style="t5" />
  )

  return (
    <ButtonBase {...buttonBaseProps}>
      <div className="flex items-center justify-between">
        {
          isLabelVisible ? (
            <div className="flex">
              {textContent}
              {' '}
              <Text message={label} style="eye3" color="gold-50" tag="sup" />
            </div>
          ) : (
            textContent
          )
        }
        {
          withArrow && (
            <Icon className="ml-16 flex-none" name="other/long-arrow-right" />
          )
        }
      </div>
      {children}
    </ButtonBase>
  )
}


export default ActionListItem
