import React from 'react'
import cx from 'classnames'
import { Message, useIntl } from 'intl'
import { Href } from 'components/navigation'

import messages from './messages'
import s from './Breadcrumbs.module.css'


type BreadcrumbsItem = {
  title: string | Intl.Message
  to?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export type BreadcrumbsProps = {
  className?: string
  items: BreadcrumbsItem[]
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (props) => {
  const { className, items } = props

  const intl = useIntl()

  return (
    <nav className={cx(s.breadcrumbs, className)} aria-label={intl.formatMessage(messages.ariaLabel)} data-testid="breadcrumbs">
      <ol className="flex">
        {
          items.map(({ title, to, onClick }, index) => (
            <li
              key={index}
              className={s.navItem}
              aria-current={to ? null : 'page'}
              data-testid={to ? 'breadcrumbsItem' : 'breadcrumbsCurrent'}
            >
              {
                to ? (
                  <Href className={s.link} to={to} onClick={onClick}>
                    <Message value={title} html />
                  </Href>
                ) : (
                  <Message value={title} />
                )
              }
            </li>
          ))
        }
      </ol>
    </nav>
  )
}


export default Breadcrumbs
