export default {
  prev: {
    long: {
      en: 'Prev page',
    },
    short: {
      en: 'Prev',
    },
  },
  next: {
    long: {
      en: 'Next page',
    },
    short: {
      en: 'Next',
    },
  },
}
