import React from 'react'


export type TabsContentProps = {
  id: string
} & ({
  title: Intl.Message | string
  titleContent?: never
} | {
  title?: never
  titleContent: React.ReactNode
})

const Content: React.CFC<TabsContentProps> = (props) => (
  <>{props.children}</>
)


export default Content
