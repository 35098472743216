import React from 'react'
import cx from 'classnames'
import { Menu } from '@headlessui/react'

import { Href } from 'components/navigation'

import { Text } from 'components/dataDisplay'

import s from './Item.module.css'


export type ItemProps = {
  className?: string
  title?: string | Intl.Message
  to?: string
  loading?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

const Item: React.CFC<ItemProps> = (props) => {
  const { children, className, title, to, disabled, loading, onClick } = props

  const itemClassName = cx('relative block', s.item, className)

  return (
    <Menu.Item
      as={Href}
      className={itemClassName}
      to={to}
      disabled={disabled}
      onClick={onClick}
    >
      {({ active }) => {
        const contentClassName = cx('w-full whitespace-nowrap p-16 text-left', s.button, {
          [s.active]: active,
          [s.disabled]: disabled || loading,
        })

        return (
          <div className={contentClassName}>
            {
              Boolean(title) && (
                <Text style="p4" message={title} />
              )
            }
            {children}
          </div>
        )
      }}
    </Menu.Item>
  )
}


export default React.memo(Item)
