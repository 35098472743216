import { createContext, useContext } from 'react'
import type { MutableRefObject, MouseEvent } from 'react'


export type HoverablePopoverContextValue = {
  hoverClosePopoverPanel: (event?: MouseEvent<HTMLElement>) => void
  buttonRef: MutableRefObject<HTMLButtonElement>
}

export const HoverablePopoverContext = createContext<HoverablePopoverContextValue>(null)
export const useHoverablePopoverContext = () => useContext(HoverablePopoverContext)
