import React from 'react'
import { Popover } from '@headlessui/react'

import { useHoverablePopoverContext } from '../../util'


type ButtonComponent = typeof Popover.Button

const Button: ButtonComponent & { ref?: never } = (props) => {
  const { buttonRef } = useHoverablePopoverContext()

  return (
    <Popover.Button
      {...props}
      ref={buttonRef}
    />
  )
}

Button.displayName = 'HoverablePopover.Button'


export default Button
