/*

  ATTN If you need to load data based on active page then you need to use query params!

  Example:

  <Products>
  <Pagination />

  const Products = () => {
    const [ { page } ] = useSearchParams()

    // load data based on "page" value, on paginate its value will be changed and Products component will be re-rendered
  }

 */

import React, { useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { useSearchParams } from 'router'
import { useDevice } from 'device'

import { Icon, Text } from 'components/dataDisplay'

import s from './Pagination.module.css'
import messages from './messages'


type PaginationProps = {
  className?: string
  pageCount: number
  queryKey?: string
  scroll?: boolean
  onChange?: (pageNum: number) => void
}

const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const { className, pageCount, queryKey = 'page', scroll = true, onChange } = props

  const { isMobile } = useDevice()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const page = parseInt(searchParams[queryKey]) || 0

  const handleClick = useCallback(({ selected: pageNum }) => {
    setSearchParams((prev) => ({
      ...prev,
      [queryKey]: pageNum,
    }), { scroll, replace: false })

    if (typeof onChange === 'function') {
      onChange(pageNum)
    }
  }, [ queryKey, scroll, onChange, setSearchParams ])

  const previousLabelNode = (
    <>
      <Icon name="other/long-arrow-left" color="gold-50" />
      <Text message={isMobile ? messages.prev.short : messages.prev.long} style="t5" color="gold-50" />
    </>
  )

  const breakLabelNode = (
    <span>...</span>
  )

  const nextLabelNode = (
    <>
      <Text message={isMobile ? messages.next.short : messages.next.long} style="t5" color="gold-50" />
      <Icon name="other/long-arrow-right" color="gold-50" />
    </>
  )

  return (
    <div className={className}>
      <ReactPaginate
        forcePage={page}
        containerClassName={s.pagination}
        pageClassName={s.page}
        pageLinkClassName={s.pageLink}
        previousClassName={s.page}
        previousLinkClassName={s.arrowLink}
        nextClassName={s.page}
        nextLinkClassName={s.arrowLink}
        breakClassName={s.page}
        breakLinkClassName={s.pageLink}
        activeClassName={s.active}
        pageCount={pageCount}
        pageRangeDisplayed={isMobile ? 1 : 4}
        marginPagesDisplayed={isMobile ? 1 : 2}
        previousLabel={previousLabelNode}
        nextLabel={nextLabelNode}
        breakLabel={breakLabelNode}
        onPageChange={handleClick}
      />
    </div>
  )
}


export default React.memo(Pagination)
