import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'router'


type UseQueryTabsProps = {
  availableTabIds: string[]
  defaultActiveTab?: string
  withScroll?: boolean
}

type Result = [ string, (id: string) => void ]

// Sync tabs state with search params
const useQueryTabs = (props: UseQueryTabsProps): Result => {
  const { availableTabIds = [], defaultActiveTab, withScroll } = props

  const [ searchParams, setSearchParams ] = useSearchParams()

  // tab from the search params
  const { tab } = searchParams

  const activeTab = useMemo(() => {
    if (availableTabIds.includes(tab)) {
      return tab
    }

    if (defaultActiveTab) {
      return defaultActiveTab
    }

    return availableTabIds.length ? availableTabIds[0] : ''
  }, [ tab, availableTabIds, defaultActiveTab ])

  const setActiveTab = useCallback((id: string) => {
    setSearchParams((prev) => ({
      ...prev,
      tab: id,
    }), { replace: true, scroll: withScroll })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [
    activeTab,
    setActiveTab,
  ]
}


export default useQueryTabs
